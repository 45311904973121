import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
  },
  barContainer: {
    width: "100%",
    height: "100px",
    background: "transparent",
    padding: '0',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    zIndex: "10",
    "@media (max-width: 1000px)": {
      height: "50px",
      justifyContent: "center",
    }
  },
  iconContainer: {
    width: "30%",
    minWidth: "300px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "50px",
    "@media (max-width: 1000px)": {
      justifyContent: "center",
      paddingLeft: "0"
    }
  },
  iconImg: {
    maxHeight: "75px",
    maxWidth: "250px",
    "@media (max-width: 1000px)": {
      maxWidth: "150px"
    }
  },
  menusContainer: {
    width: "70%",
    paddingRight: "8%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "@media (max-width: 1000px)": {
      display: "none",
    },
    "@media (max-width: 1600px)": {
      paddingRight: "2px"
    },
  },
  menuItem: {
    padding: "19px 13px",
    color: "black",
    fontSize: "1rem",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    "@media (max-width: 1325px)": {
      fontSize: "12px !important",
      padding: "19px 8px",
    }
  },
  menuItemActive: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: "19px 13px",
    color: "black",
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    "@media (max-width: 1025px)": {
      fontSize: "12px !important",
    }
  },
  burgerContainer: {
    display: "none",
    position: "absolute",
    right: 0,
    marginRight: "10px",
    "@media (max-width: 1000px)": {
      display: "block"
    },
  },
  burger: {
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
    },
  },
  pattie: {
    minWidth: "20px",
    minHeight: "2px",
    margin: "4px",
    background: theme.palette.primary.main,
  },
  mobileMenusContainerOuter: {
    transform: ({ openMenu }) => `translate(${openMenu}%,0%)`,
    background: theme.palette.primary.main,
    width: "100%",
    maxWidth: "300px",
    right: "0px",
    top: "0px",
    bottom: '0',
    position: "fixed",
    zIndex: 20,
    transition: '0.5s all ease-in',
  },
  mobileMenusContainerInner: {
    background: theme.palette.secondary.main,
    width: "100%",
    maxWidth: "290px",
    right: "0px",
    top: "0px",
    bottom: '0',
    position: "fixed"
  },
  mobileMenusContainerClose: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    textAlign: 'right',
    padding: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  mobileMenusContainer: {
    height: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  mobileMenuItem: {
    padding: "19px 15px",
    color: "lightgrey",
    fontSize: "1rem",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    }
  },
}));

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(100);
  const classes = useStyles({ openMenu: openMenu });
  const { websiteColors } = useContext(DealerContext);

  const handleMobileMenu = () => {
    setOpenMenu(openMenu === 100 ? 0 : 100);
  };

  const aLinks = [
    {
      name: 'Home',
      to: '/home'
    },
    {
      name: 'Vehicles',
      to: '/new'
    },
    {
      name: 'Promotions',
      to: '/promotions'
    },
    {
      name: 'Pre-owned',
      to: '/pre-owned'
    },
    {
      name: 'Aftersales',
      to: '/aftersales'
    },
    {
      name: 'About Us',
      to: '/about-us'
    },
    {
      name: 'Finance',
      to: '/apply-for-finance'
    },
    {
      name: 'Contact Us',
      to: '/contact-us'
    }
  ]


  return (
    <div className={classes.root}>
      <div className={classes.barContainer}>
        <div className={classes.iconContainer}>
          <Link to="/" >
            <img alt="" className={classes.iconImg} src={websiteColors?.logo}></img>
          </Link>
        </div>

        {/* Only visible for mobile devices */}
        <div className={classes.burgerContainer}>
          <div className={classes.burger} onClick={handleMobileMenu}>
            <div className={classes.pattie}></div>
            <div className={classes.pattie}></div>
            <div className={classes.pattie}></div>
          </div>
          <div className={classes.mobileMenusContainerOuter}>
            <div className={classes.mobileMenusContainerInner}>
              <div className={classes.mobileMenusContainerClose} onClick={handleMobileMenu}>
                <CloseIcon />
              </div>
              <div className={classes.mobileMenusContainer}>
                {
                  aLinks.map(v => (
                    <Link key={v.name} className={classes.mobileMenuItem} onClick={handleMobileMenu} to={v.to}>
                      {v.name}
                    </Link>
                  ))
                }
              </div>
            </div>
          </div>
        </div>

        {/* Only visible for larger screens */}
        <div className={classes.menusContainer}>
          {
            aLinks.map(v => (
              <NavLink key={`${v.name}_desk`} className={classes.menuItem} activeClassName={classes.menuItemActive} to={v.to}>
                {v.name}
              </NavLink>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default TopBar;