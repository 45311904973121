import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from "../shared/contexts/VehicleContext";
import Logo from '../shared/assets/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: '40px 100px',
    background: theme.palette.secondary.main,
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px'
    }
  },
  sectionHeadingContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    paddingBottom: "20px",
    borderBottom: "2px lightgrey solid",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 1000px)": {
      marginTop: "30px"
    }
  },
  sectionHeading: {
    color: "white",
    fontSize: '1.2rem',
    fontWeight: "600",
    "@media (max-width: 1000px)": {
      textAlign: "center"
    }
  },
  linkText: {
    fontSize: '0.8rem',
    color: "lightgrey",
    margin: "5px 0",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main
    },
    "@media (max-width: 1000px)": {
      textAlign: "center"
    }
  },
  imgContainer: {
    maxHeight: "50px",
    display: "flex",
    justifyContent: "center"
  },
  img: {
    width: "150px"
  },
  socialLinkHolder: {
    display: 'flex',
    justifyContent: "center"
  },
  socialLink: {
    width: 'calc(100%/11)!important',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    }
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { dealerSocials } = useContext(DealerContext);
  const { loading: loadingVehicles, newVehiclesList } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} direction="row" justifyContent='center'>

        {/* Models section */}
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Typography className={classes.sectionHeading}>Models</Typography>
          </div>
          {
            !loadingVehicles &&
            newVehiclesList?.length > 0 && newVehiclesList.map((model, m) =>
              <Link key={m} to={`/new/${model.ownedModelId}`}>
                <Typography className={classes.linkText}>{model.model}</Typography>
              </Link>
            )
          }
        </Grid>

        {/* Legal section */}
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Typography className={classes.sectionHeading}>Legal</Typography>
          </div>
          <Link to="/cookies">
            <Typography className={classes.linkText}>Website Usage</Typography>
          </Link>
          <Link to="/popi">
            <Typography className={classes.linkText}>Privacy Policy</Typography>
          </Link>
          <Link to="/tcs">
            <Typography className={classes.linkText}>Terms & Conditions</Typography>
          </Link>
        </Grid>

        {/* Connect section */}
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Typography className={classes.sectionHeading}>Connect</Typography>
          </div>
          <Link to="/contact-us">
            <Typography className={classes.linkText}>Contact us</Typography>
          </Link>
        </Grid>

        {/* Logo & Social media links section */}
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
          <Link to="/" className={classes.imgContainer}>
            <img alt="" className={classes.img} src={websiteColors?.logo}></img>
          </Link>
          </div>
          <div className={classes.socialLinkHolder}>
            {dealerSocials?.facebook ?
              (<div className={classes.socialLink}>
                <a href={dealerSocials.facebook} target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                  <FacebookIcon className={classes.socialLinkIcon} />
                </a>
              </div>)
              : null
            }

            {dealerSocials?.instagram ?
              (<div className={classes.socialLink}>
                <a href={dealerSocials.instagram} target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                  <InstagramIcon className={classes.socialLinkIcon} />
                </a>
              </div>)
              : null
            }
          </div>
        </Grid>∂
      </Grid>
    </div>
  )
};

export default Footer;