import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { BASE_URI } from "../shared/Constants";
import { VehiclesFilterV2 } from "@red-build/leadcentre";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "70px 15px",
    display: "flex",
    flexDirection: 'column'
  },
  innerHolderFilter: {
    padding: '0px 20px 20px 20px',
    maxWidth: "1770px",
    width: '100%',
    margin: " 0 auto",
  },
  HolderFilter: {
    paddingTop: '40px',
  },
  innerHolder: {
    maxWidth: "1770px",
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    width: "100%",
  },
  card: {
    width: "calc(100% / 3 - 80px )",
    minWidth: "calc(100% / 3 - 80px )",
    cursor: 'pointer',
    background: theme.palette.primary.main,
    boxShadow: `0px 3px 15px 0px black`,
    margin: "30px 40px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '5px',
    "@media (max-width: 700px)": {
      width: "100%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
    "@media (min-width: 1000px) and (max-width: 1500px)": {
      width: "calc(100% / 3 - 20px )",
    },
  },
  cardTextHolder: {
    padding: "20px 40px",
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: '0px 0px 5px 5px',
    "@media (max-width: 768px)": {
      padding: "20px 20px",
    },
  },
  cardImgContainer: {
    background: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: '8px'
  },
  cardImg: {
    width: "100%",
    borderRadius: "5px 5px 0px 0px",
  },
  cardTextHolderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  btnGroupHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItem: "center",
    marginTop: '20px'
  },
  cta: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: ({ websiteColors }) => `${theme.palette.primary.main}`,
    color: "white",
    cursor: "pointer",
    display: 'block',
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  ctaG: {
    border: "none",
    borderRadius: '50px',
    padding: "10px 40px",
    background: theme.palette.secondary.main,
    color: "white",
    cursor: "pointer",
    display: 'block',
    "&:hover": {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.main,
      boxShadow: `0 0 5px 1px ${theme.palette.secondary.main}`,
    },
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  pagHolder: {
    display: 'flex',
    justifyContent: 'center'
  },
  pagBtn: {
    border: "none",
    borderRadius: '1rem',
    padding: "10px 40px",
    background: ({ websiteColors }) => `${theme.palette.primary.main}`,
    color: "white",
    cursor: "pointer",
    margin: '10px',
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  pagNumberHolder: {
    textAlign: 'right',
    width: '100%',
    padding: '5px 10px',
  },
  intro: {
    textAlign: 'center',
    marginTop: '0px',
    fontSize: '2.8rem',
  },
  red: {
    color: theme.palette.secondary.main,
  },
  MuiButtonLabel: {
color: '#fff',
  },
}));

const formatter = new Intl.NumberFormat('af', {
  style: 'currency',
  currency: 'ZAR',
  minimumFractionDigits: 2
});

const UsedVehicles = () => {
  const history = useHistory();
  const classes = useStyles();
  const { vehiclesList, loading } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const [vehiclesPag, setVehiclesPag] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(20);

  const handleRedirect = (v) => {
    history.push(`/pre-owned/${v.stockId}`);
  };

  useEffect(() => {
    let startIndex = 0;
    let endIndex = 20;
    let pag = [];
    // TODO: use map
    for (let index = 0; index <= vehiclesList?.length / 20; index++) {
      pag?.push(vehiclesList?.slice(startIndex, endIndex))
      startIndex = endIndex
      endIndex = endIndex + 20
    }

    setVehiclesPag(pag)
  }, [vehiclesList])

  const handleNextPage = () => {
    if (vehiclesPag[pageIndex].length >= 20) {
      setCount(count + 20)
      setPageIndex(pageIndex + 1)
    }
  }

  const handleBackPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  }

  const getFuelType = (fuelType) => {
    switch (fuelType?.toLowerCase()) {
      case 'p':
      case 'petrol':
        return 'Petrol';
      case 'h':
      case 'hybrid':
        return 'Hybrid';
      default:
        return 'Diesel';
    }
  };
  

  return (
    <div className={classes.root}>
      <div className={classes.HolderFilter}>
        <p className={classes.intro}>Search Our <span className={classes.red}>Vehicles</span></p>
        {
          !loading &&
          <div className={classes.innerHolderFilter}>
            <VehiclesFilterV2
              pageSize={20}
              dealerId={globalDealer?.dealerId}
              // motorgroupId={globalDealer?.motorgroupId || 0}
              orientation="row"
              updateFilteredVehicles={setVehiclesPag}
              loading={loading}
              vehicles={vehiclesList}
              base_uri={BASE_URI}
              websiteColors={websiteColors}
            />
          </div>
        }
      </div>
      <div className={classes.innerHolder}>
        {
          vehiclesPag[pageIndex]?.map((v, index) => (
            <div key={index} className={classes.card} onClick={() => { handleRedirect(v) }}>
              <div className={classes.cardImgContainer}>
                <img alt="" className={classes.cardImg} src={v?.image} />
              </div>
              <div className={classes.cardTextHolder}>
                <div className={classes.cardTextTitleHolder}>
                  <div className={classes.cardTextTitle}>{v?.model}</div>
                  <div className={classes.cardTextTitle}>{v?.modelRange}</div>
                </div>
                <div className={classes.cardTextHolderGroup}>
                  <div>Mileage</div>
                  <div>{v?.mileage} km</div>
                </div>
                <div className={classes.cardTextHolderGroup}>
                  <div>Price</div>
                  <div>{formatter?.format(v?.price)}</div>
                </div>
                <div className={classes.cardTextHolderGroup}>
                  <div>Colour</div>
                  <div>{v?.colour}</div>
                </div>
                <div className={classes.cardTextHolderGroup}>
                  <div>Year</div>
                  <div>{v?.year}</div>
                </div>
                <div className={classes.cardTextHolderGroup}>
                  <div>Fuel Type</div>
                  <div>{getFuelType(v?.fuelType)}</div>
                </div>
                <div className={classes.btnGroupHolder}>
                  <button className={classes.ctaG} onClick={() => { handleRedirect(v) }}>LEARN MORE</button>
                </div>
              </div>
            </div>
          ))
        }
        <div className={classes.pagNumberHolder}>{pageIndex + 1} / {(vehiclesList?.length / 20).toFixed(0)}</div>
      </div>
      <div className={classes.pagHolder}>
        <button className={classes.pagBtn} onClick={() => { handleBackPage() }}>Back </button>
        <button className={classes.pagBtn} onClick={() => { handleNextPage() }}>Next</button>
      </div>
    </div>
  );
};

export default UsedVehicles;
