import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import SectionImage1 from '../shared/assets/about_us_1.jpg';
import SectionImage2 from '../shared/assets/about_us_2.jpg';
import SectionImage3 from '../shared/assets/about_us_3.jpg';
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    padding: "70px 15px",
  },
  sectionTitleContainer: {
    padding: "100px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      padding: "25px 0",
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      padding: "50px 0",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      padding: "75px 0",
    }
  },
  sectionTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "4rem",
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    "@media (max-width: 350px)": {
      fontSize: "1.5rem"
    },
    "@media (min-width: 351px) and (max-width: 500px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.5rem"
    }
  },
  sectionTitleAccent: {
    color: theme.palette.primary.main
  },
  sectionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0",
  },
  sectionContent: {
    width: "100%",
    maxWidth: "800px",
    padding: "20px",
    textAlign: "justify",
  },
  sectionImg: {
    width: "100%",
    height: "auto",
    boxShadow: `0 0 20px 0 ${theme.palette.secondary.main}`,
  },
  sectionText: {
    fontSize: "1.4rem",
    color: theme.palette.secondary.main,
    "@media (max-width: 500px)": {
      fontSize: "0.8em",
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "1.2rem",
    }
  },
}));

const AboutUs = () => {
  const { globalDealer } = useContext(DealerContext);
  const classes = useStyles();

  const dealerSections = {
    'BAIC Springs': {
      image: SectionImage1,
      content: (
        <>
          <p className={classes.sectionText}>
            Welcome to BAIC Springs, your trusted destination for premium automotive excellence in the heart of Gauteng. As authorized dealers for BAIC (Beijing Automotive Industry Corporation) in Springs, we bring you a range of world-class vehicles designed to match your lifestyle and needs.
          </p>
          <p className={classes.sectionText}><strong>Our Vision</strong></p>
          <p className={classes.sectionText}>
            Our vision at BAIC Springs is to provide our customers with a seamless vehicle purchasing experience. We are dedicated to ensuring that our clients leave our dealership satisfied and excited about their new vehicle.
          </p>
          <p className={classes.sectionText}><strong>Customer Commitment</strong></p>
          <p className={classes.sectionText}>
            We pride ourselves on our customer service, ensuring every client receives personalized attention and care. Our knowledgeable staff are always available to assist you with any inquiries.
          </p>
          <p className={classes.sectionText}><strong>Why Choose Us?</strong></p>
          <p className={classes.sectionText}>
            At BAIC Springs, we understand that purchasing a vehicle is a significant investment. That’s why we offer competitive pricing and flexible financing options to suit your needs.
          </p>
        </>
      )
    },
    'BAIC Nigel': {
      image: SectionImage2,
      content: (
        <>
          <p className={classes.sectionText}>
          Welcome to BAIC Nigel, your trusted destinations for premium automotive excellence in the heart of Gauteng. As authorized dealers for BAIC (Beijing Automotive Industry Corporation) in Nigel, we bring you a range of world-class vehicles designed to match your lifestyle and needs. 
          </p>
          <p className={classes.sectionText}><strong>About BAIC </strong></p>
          <p className={classes.sectionText}>
          Founded in 1958, BAIC is one of the largest automotive manufacturers in China, known for its commitment to innovation, quality, and sustainability. With a presence in over 130 countries, BAIC is renowned for producing cutting-edge vehicles that combine advanced technology with stylish design. As part of BAIC South Africa, our dealerships in Nigel uphold the same values of precision engineering and customer satisfaction that have made BAIC a global leader in the automotive industry. 
          </p>
          <p className={classes.sectionText}><strong>Explore Our Models </strong></p>
          <p className={classes.sectionText}>
          At BAIC Nigel, we showcase some of the finest models from the BAIC lineup, including the highly popular X55 and the rugged B40. <br/><br/>BAIC X55: A sophisticated crossover that delivers both style and performance. With its sleek, modern design, turbocharged engine, and a host of smart features, the X55 is perfect for city driving and weekend getaways alike. Experience the future of driving with advanced safety features, a luxurious interior, and a dynamic driving experience. <br/><br/>BAIC B40: For those who crave adventure, the B40 is the ultimate off-road companion. With its bold design and military-grade capabilities, the B40 is built to tackle the toughest terrains while offering comfort and cutting-edge technology. Whether you’re navigating the urban jungle or exploring the great outdoors, the B40 is engineered to handle it all. 
          </p>
          <p className={classes.sectionText}><strong>Our Commitment to Quality & Service </strong></p>
          <p className={classes.sectionText}>
          At BAIC Nigel, we understand that buying a vehicle is not just a purchase, but an experience. That’s why we are committed to offering top-quality service from the moment you step into our showrooms. Our knowledgeable sales teams are here to help you find the perfect vehicle to meet your needs, while our skilled technicians ensure that your car remains in pristine condition through reliable after-sales support. <br/><br/>We pride ourselves on delivering not only exceptional vehicles but also an unmatched customer experience. From expert advice to personalized financing options and aftercare services, we strive to exceed your expectations every step of the way. 
          </p>
        </>
      )
    }
  };

  // Check if the current dealer section is defined
  const currentDealerSection = dealerSections[globalDealer?.name];

  return (
    <div className={classes.root}>
      <div className={classes.sectionContainer}>
        {/* Image container */}
        <img 
          src={currentDealerSection ? currentDealerSection.image : SectionImage3} 
          alt="BAIC Cars" 
          className={classes.sectionImg} 
        />

        {/* About Us heading under the image */}
        <div className={classes.sectionTitleContainer}>
          <Typography variant="h1" className={classes.sectionTitle}>
            About <span className={classes.sectionTitleAccent}>Us</span>
          </Typography>
        </div>

        {/* About Us content under the heading */}
        <div className={classes.sectionContent}>
          {currentDealerSection 
            ? currentDealerSection.content 
            :     <><p className={classes.sectionText}>
             Welcome to BAIC Springs, your trusted destination for premium automotive excellence in the heart of
Gauteng. As authorized dealers for BAIC (Beijing Automotive Industry Corporation) in Springs, we bring
you a range of world-class vehicles designed to match your lifestyle and needs.
            </p><p className={classes.sectionText}><strong>Explore Our Models</strong></p><p className={classes.sectionText}>
            At BAIC Springs, we showcase some of the finest models from the BAIC lineup, including the highly
popular X55 and the rugged B40. <br/><br/>
BAIC X55: A sophisticated crossover that delivers both style and performance. With its sleek, modern
design, turbocharged engine, and a host of smart features, the X55 is perfect for city driving and
weekend getaways alike. Experience the future of driving with advanced safety features, a luxurious
interior, and a dynamic driving experience.<br/><br/>BAIC B40: For those who crave adventure, the B40 is the ultimate off-road companion. With its bold
design and military-grade capabilities, the B40 is built to tackle the toughest terrains while offering
comfort and cutting-edge technology. Whether you’re navigating the urban jungle or exploring the great
outdoors, the B40 is engineered to handle it all.


              </p><p className={classes.sectionText}><strong>Our Commitment to Quality & Service</strong></p><p className={classes.sectionText}>
              At BAIC Springs, we understand that buying a vehicle is not just a purchase, but an experience. That’s
why we are committed to offering top-quality service from the moment you step into our showrooms.
Our knowledgeable sales teams are here to help you find the perfect vehicle to meet your needs, while
our skilled technicians ensure that your car remains in pristine condition through reliable after-sales
support. </p><p className={classes.sectionText}><strong>Why Choose Us?</strong></p><p className={classes.sectionText}>
              We pride ourselves on delivering not only exceptional vehicles but also an unmatched customer
experience. From expert advice to personalized financing options and aftercare services, we strive to
exceed your expectations every step of the way.
              </p></>}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
