/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import Logo from '../shared/assets/logo.png';

/*
 *  Note:
 *  Could not get proper carousel effect working for three items in the screen, hence
 *  no transition effect is implemented at this time
 */

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  sectionTitleContainer: {
    width: "100%",
    padding: "100px 0",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 800px)": {
      padding: "50px 0",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      padding: "75px 0",
    }
  },
  sectionTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "4rem",
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    "@media (max-width: 350px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 351px) and (max-width: 500px)": {
      fontSize: "2.5rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.5rem"
    }
  },
  sectionTitleAccent: {
    color: theme.palette.primary.main
  },
  primaryDivider: {
    width: "100%",
    height: "10vh",
    background: theme.palette.primary.main,
    position: "absolute",
    "@media (max-width: 500px)": {
      height: "7vh",
    }
  },
  carouselArrowsContainer: {
    width: "100%",
    position: "absolute",
    padding: "0 50px",
    height: "200px",
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    "@media (max-width: 500px)": {
      height: "150px"
    }
  },
  carouselArrowContainer: {
    position: "relative",
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70px",
    height: "70px",
    margin: "10px",
    background: 'transparent',
    border: `${theme.palette.secondary.main} 5px solid`,
    color: theme.palette.secondary.main,
    borderRadius: '50%',
    opacity: "0.8",
    "&:hover": {
      opacity: "1",
      border: `white 5px solid`,
      color: "white",
      cursor: 'pointer'
    },
    "@media (max-width: 500px)": {
      borderWidth: "1px",
      width: "35px",
      height: "35px"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      borderWidth: "2px",
      width: "50px",
      height: "50px"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      borderWidth: "3px",
      width: "60px",
      height: "60px"
    }
  },
  carouselArrowIcon: {
    fontSize: 40,
    "@media (max-width: 500px)": {
      fontSize: "10px"
    },
    "@media (max-width: 1000px)": {
      fontSize: "20px"
    },
  },
  carouselItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "calc((100% - 18%) / 3)",
    margin: "0 3%",
    '@media (max-width:500px)': {
      display: "none"
    },
    '@media (min-width:501px) (max-width:1100px)': {
      width: "calc((100% - 12%) / 2)",
    }
  },
  carouselItemFocussed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "calc((100% - 18%) / 3)",
    margin: "0 3%",
    '@media (max-width:500px)': {
      minWidth: "calc(100% - 6%)",
    },
    '@media (min-width:501px) (max-width:1100px)': {
      width: "calc((100% - 12%) / 2)",
    }
  },
  cardsOuterContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  cardsContainer: {
    //transform: ({ cardIndex }) => `translate(-${(100 / 3) * cardIndex}%, 0px)`,
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    transition: "0.5s all ease-in",
    position: "relative",
    zIndex: 2
  },
  cardContainerOuter: {
    minWidth: "25vw",
    margin: "0 3%",
    background: "white",
    borderRadius: "10px"
  },
  cardContainerInner: {
    minWidth: "200px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    color: "#b2b1b1",
    fontSize: "1em",
    fontWeight: "200"
  },
  cardSloganText: {
    textTransform: "capitalize",
    textAlign: "center",
    fontSize: "2em",
    fontWeight: "400",
    color: theme.palette.secondary.main,
    "@media (max-width: 800px)": {
      fontSize: "1.5em"
    }
  },
  cardModelText: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "3em",
    fontWeight: "600",
    color: theme.palette.secondary.main,
    "@media (max-width: 800px)": {
      fontSize: "2.5em"
    }
  },
  logoImgContainer: {
    display: "flex",
    justifyContent: "center"
  },
  logoImg: {
    width: "60%",
    "@media (max-width: 500px)": {
      width: "40%"
    }
  },
  modelSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "50px"
  },
  modelDetailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    zIndex: 2
  },
  modelButton: {
    width: "50%",
    textAlign: "center",
    padding: "10px",
    fontSize: "1.5em",
    color: "white",
    border: "none",
    borderRadius: "10px",
    background: theme.palette.secondary.main,
    boxShadow: `0 0 5px 0 ${theme.palette.secondary.main}`,
    '&:hover': {
      cursor: 'pointer'
    },
    "@media (max-width: 800px)": {
      fontSize: "1em"
    }
  },
  modelButtonFocussed: {
    width: "80%",
    textAlign: "center",
    padding: "20px",
    fontSize: "2.5em",
    color: "white",
    border: "none",
    borderRadius: "10px",
    background: theme.palette.primary.main,
    boxShadow: `0 0 5px 0 ${theme.palette.secondary.main}`,
    '&:hover': {
      cursor: 'pointer'
    },
    "@media (max-width: 500px)": {
      fontSize: "1.5em",
      padding: "15px",
      width: "40%"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "1.5em",
      padding: "15px"
    }
  },
  vehicleImg: {
    width: "100%",
    "&:hover": {
      cursor: "pointer"
    }
  },
  vehicleImgFocussed: {
    width: "50vw",
    "&:hover": {
      cursor: "pointer"
    }
  },
  videoFrameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    backgroundColor: 'black',
  },
  videoFrame: {
    width: "50%",
    aspectRatio: 16 / 9
  }
}));

const ModelsCarousel = () => {

  const [cardIndex, setcardIndex] = useState(0);
  const { newVehiclesList } = useContext(VehicleContext);
  const history = useHistory();
  const classes = useStyles({ cardIndex: cardIndex });

  const handleNextCard = () => {

    let newIndex = (cardIndex < newVehiclesList.length - 1) ? cardIndex + 1 : 0;
    setcardIndex(newIndex);

  };

  const handlePrevCard = () => {

    let newIndex = (cardIndex <= 0) ? newVehiclesList.length - 1 : cardIndex - 1
    setcardIndex(newIndex);

  };

  const getPrevIndex = () => {

    if (!newVehiclesList?.length > 0) return 0;

    let prevIndex = cardIndex - 1;

    return prevIndex < 0 ? newVehiclesList.length - 1 : prevIndex;

  };

  const getNextIndex = () => {

    if (!newVehiclesList?.length > 0) return 0;

    let nextIndex = cardIndex + 1;

    return nextIndex > newVehiclesList.length - 1 ? 0 : nextIndex;

  };

  const handleRedirect = (ownedModelId) => {

    history.push(`/new/${ownedModelId}`);

  };

  const selectVehicle = (selectedIndex) => {

    // eslint-disable-next-line default-case
    switch (selectedIndex) {
      case getPrevIndex(): handlePrevCard(true); break;
      case getNextIndex(): handleNextCard(true); break;
    }

  };

  const renderVehicleCard = (vehicleIndex) => {

    let vehicle = newVehiclesList[vehicleIndex];
    let isFocussed = vehicleIndex === cardIndex;

    return (
      <div className={isFocussed ? classes.carouselItemFocussed : classes.carouselItem}>

        {
          isFocussed === true &&
          <img className={classes.logoImg} src={Logo} />
        }

        <div className={isFocussed ? classes.modelButtonFocussed : classes.modelButton}
          onClick={() => { isFocussed ? handleRedirect(vehicle?.ownedModelId) : selectVehicle(vehicleIndex) }}
        >
          {vehicle?.title}
        </div>

        <div className={classes.modelSection}>
          <div className={classes.modelDetailContainer}>
            <img
              className={isFocussed ? classes.vehicleImgFocussed : classes.vehicleImg}
              src={vehicle?.image}
              onClick={() => { isFocussed ? handleRedirect(vehicle?.ownedModelId) : selectVehicle(vehicleIndex) }}
            />
            {
              isFocussed === true &&
              <>
                <Typography className={classes.cardSloganText}>{vehicle?.slogan}</Typography>
                <Typography className={classes.cardModelText}>{vehicle?.model}</Typography>
              </>
            }
          </div>
        </div>

      </div>
    );
  };

  const getVehicleVideo = (vehicleIndex) => {

    let vehicle = newVehiclesList[vehicleIndex];
    let modelName = vehicle?.model?.trim().toLowerCase();

    return modelName?.includes("55")
      ? "https://www.youtube.com/embed/LSGrHoezrpw"
      : modelName?.includes("40")
        ? "https://www.youtube.com/embed/Z9lx47j_UlI"
        : "";

  };

  return (
    <div className={classes.root}>

      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle}>Model <span className={classes.sectionTitleAccent}>Range</span></Typography>
      </div>

      <div className={classes.cardsOuterContainer}>
        {
          newVehiclesList?.length > 0 &&
          <div className={classes.cardsContainer}>
            {renderVehicleCard(getPrevIndex())}
            {renderVehicleCard(cardIndex)}
            {renderVehicleCard(getNextIndex())}
          </div>
        }

        <div className={classes.primaryDivider}></div>

        <div className={classes.carouselArrowsContainer}>
          <div className={classes.carouselArrowContainer} onClick={handlePrevCard}>
            <ArrowBackIosNewIcon className={classes.carouselArrowIcon} />
          </div>

          <div className={classes.carouselArrowContainer} onClick={handleNextCard}>
            <ArrowForwardIosIcon className={classes.carouselArrowIcon} />
          </div>
        </div>

      </div>

      {
        newVehiclesList?.length > 0 &&
        <div className={classes.videoFrameContainer}>
          <iframe className={classes.videoFrame} src={getVehicleVideo(cardIndex)} frameBorder="0" />
        </div>
      }

    </div>
  );
};

export default ModelsCarousel;